exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-categories-jsx": () => import("./../../../src/pages/categories.jsx" /* webpackChunkName: "component---src-pages-categories-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-success-jsx": () => import("./../../../src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-templates-category-template-jsx": () => import("./../../../src/templates/category-template.jsx" /* webpackChunkName: "component---src-templates-category-template-jsx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-benefits-benefits-of-studying-abroad-best-countries-to-study-abroad-for-indian-students-in-2024-your-complete-guide-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/atharva/IdeaProjects/Arbit/educateus/content/Benefits/Benefits of Studying Abroad/Best Countries to Study Abroad for Indian Students in 2024, Your Complete Guide.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-benefits-benefits-of-studying-abroad-best-countries-to-study-abroad-for-indian-students-in-2024-your-complete-guide-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-benefits-internship-and-job-opportunities-internship-and-job-opportunities-for-international-students-a-comprehensive-guide-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/atharva/IdeaProjects/Arbit/educateus/content/Benefits/internship and job opportunities/Internship and Job Opportunities for International Students-A Comprehensive Guide.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-benefits-internship-and-job-opportunities-internship-and-job-opportunities-for-international-students-a-comprehensive-guide-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-care-taking-healthcare-healthcare-and-insurance-for-indian-students-abroad-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/atharva/IdeaProjects/Arbit/educateus/content/Care-Taking/healthcare/Healthcare and Insurance for Indian Students Abroad.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-care-taking-healthcare-healthcare-and-insurance-for-indian-students-abroad-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-care-taking-learning-the-local-language-while-studying-abroad-the-importance-of-learning-the-local-language-while-studying-abroad-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/atharva/IdeaProjects/Arbit/educateus/content/Care-Taking/Learning the Local Language While Studying Abroad/The Importance of Learning the Local Language While Studying Abroad.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-care-taking-learning-the-local-language-while-studying-abroad-the-importance-of-learning-the-local-language-while-studying-abroad-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-financing-how-to-fund-your-study-abroad-experience-scholarships-grants-and-loans-how-to-fund-your-study-abroad-experience-scholarships-grants-and-loans-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/atharva/IdeaProjects/Arbit/educateus/content/financing/How to Fund Your Study Abroad Experience, Scholarships, Grants, and Loans/How to Fund Your Study Abroad Experience, Scholarships, Grants, and Loans.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-financing-how-to-fund-your-study-abroad-experience-scholarships-grants-and-loans-how-to-fund-your-study-abroad-experience-scholarships-grants-and-loans-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-guide-guide-accommodation-how-to-find-accommodation-as-an-indian-student-abroad-a-comprehensive-guide-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/atharva/IdeaProjects/Arbit/educateus/content/Guide/guide-Accommodation/How to Find Accommodation as an Indian Student Abroad, A Comprehensive Guide.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-guide-guide-accommodation-how-to-find-accommodation-as-an-indian-student-abroad-a-comprehensive-guide-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-guide-guide-network-building-a-network-while-studying-abroad-tips-for-indian-students-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/atharva/IdeaProjects/Arbit/educateus/content/Guide/guide-network/Building a Network While Studying Abroad-Tips for Indian Students.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-guide-guide-network-building-a-network-while-studying-abroad-tips-for-indian-students-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-guide-guide-tip-a-step-by-step-guide-to-the-study-abroad-application-process-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/atharva/IdeaProjects/Arbit/educateus/content/Guide/guide-tip/A Step-by-Step Guide to the Study Abroad Application Process.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-guide-guide-tip-a-step-by-step-guide-to-the-study-abroad-application-process-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-guide-guide-usa-essential-packing-list-for-indian-students-studying-abroad-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/atharva/IdeaProjects/Arbit/educateus/content/Guide/guide-usa/Essential Packing List for Indian Students Studying Abroad.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-guide-guide-usa-essential-packing-list-for-indian-students-studying-abroad-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-study-abroad-balancing-academics-and-social-life-balancing-academics-and-social-life-while-studying-abroad-a-comprehensive-guide-for-aspiring-students-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/atharva/IdeaProjects/Arbit/educateus/content/study-abroad/Balancing Academics and Social Life/Balancing Academics and Social Life While Studying Abroad-A Comprehensive Guide for Aspiring Students.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-study-abroad-balancing-academics-and-social-life-balancing-academics-and-social-life-while-studying-abroad-a-comprehensive-guide-for-aspiring-students-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-study-abroad-how-to-choose-the-right-study-abroad-program-for-you-how-to-choose-the-right-study-abroad-program-for-you-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/atharva/IdeaProjects/Arbit/educateus/content/study-abroad/How to Choose the Right Study Abroad Program for You/How to Choose the Right Study Abroad Program for You.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-study-abroad-how-to-choose-the-right-study-abroad-program-for-you-how-to-choose-the-right-study-abroad-program-for-you-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-study-abroad-tips-for-indian-students-studying-abroad-cultural-adjustment-tips-for-indian-students-studying-abroad-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/atharva/IdeaProjects/Arbit/educateus/content/study-abroad/Tips for Indian Students Studying Abroad/Cultural Adjustment, Tips for Indian Students Studying Abroad.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-study-abroad-tips-for-indian-students-studying-abroad-cultural-adjustment-tips-for-indian-students-studying-abroad-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-study-abroad-top-10-countries-for-indian-students-to-study-abroad-top-10-countries-for-indian-students-to-study-abroad-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/atharva/IdeaProjects/Arbit/educateus/content/study-abroad/Top 10 Countries for Indian Students to Study Abroad/Top 10 Countries for Indian Students to Study Abroad.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-study-abroad-top-10-countries-for-indian-students-to-study-abroad-top-10-countries-for-indian-students-to-study-abroad-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-technology-4-things-every-programmer-should-know-the-role-of-technology-in-enhancing-the-study-abroad-experience-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/atharva/IdeaProjects/Arbit/educateus/content/technology/4-things-every-programmer-should-know/The Role of Technology in Enhancing the Study Abroad Experience.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-technology-4-things-every-programmer-should-know-the-role-of-technology-in-enhancing-the-study-abroad-experience-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-technology-balance-staying-connected-as-an-international-student-bridging-the-distance-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/atharva/IdeaProjects/Arbit/educateus/content/technology/balance/Staying Connected as an International Student, Bridging the Distance.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-technology-balance-staying-connected-as-an-international-student-bridging-the-distance-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-universities-australia-navigating-the-australian-education-system-tips-for-indian-students-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/atharva/IdeaProjects/Arbit/educateus/content/Universities/australia/Navigating the Australian Education System, Tips for Indian Students.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-universities-australia-navigating-the-australian-education-system-tips-for-indian-students-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-universities-canada-why-canada-is-a-popular-destination-for-indian-students-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/atharva/IdeaProjects/Arbit/educateus/content/Universities/canada/Why Canada Is a Popular Destination for Indian Students.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-universities-canada-why-canada-is-a-popular-destination-for-indian-students-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-universities-germany-studying-in-germany-what-indian-students-need-to-know-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/atharva/IdeaProjects/Arbit/educateus/content/Universities/germany/Studying in Germany, What Indian Students Need to Know.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-universities-germany-studying-in-germany-what-indian-students-need-to-know-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-universities-uk-living-and-studying-in-the-uk-a-guide-for-indian-students-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/atharva/IdeaProjects/Arbit/educateus/content/Universities/uk/Living and Studying in the UK, A Guide for Indian Students.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-universities-uk-living-and-studying-in-the-uk-a-guide-for-indian-students-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-universities-usa-top-universities-in-the-usa-for-indian-students-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/Users/atharva/IdeaProjects/Arbit/educateus/content/Universities/usa/Top Universities in the USA for Indian Students.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-universities-usa-top-universities-in-the-usa-for-indian-students-mdx" */)
}

